import request from '@/common/utils/request'
import Vue from "vue";

export function getVisitOrderList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/plate/get-visit-order-list',
    method: 'post',
    data
  })
}

