<template>
  <div>
    <!--    <el-row>-->
    <!--      <el-col :span="24">-->
    <!--        <span style="color: red;font-size: 20px; font-weight: 700;">注意:同一活动或电影除【时间、票数、地点】外其他必须完全一样</span>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <el-row>
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium">
            <el-form-item label="">
              <el-input
                v-model="searchForm.site"
                size="mini"
                placeholder="地点/展览"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="searchForm.film"
                size="mini"
                placeholder="电影"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                size="small"
                placeholder="请选择状态"
                v-model="searchForm.status"
                clearable
              >
                <!---1 待审核  0 未参观 1 已参观 2 取消 3 过期-->
                <el-option :key="0" label="未使用" :value="0"></el-option>
                <el-option :key="1" label="已使用" :value="1"></el-option>
                <el-option :key="2" label="取消" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                size="small"
                placeholder="请选择状态"
                v-model="searchForm.vtype"
                clearable
              >
                <!---1 电影 2 展览-->
                <el-option :key="1" label="电影" :value="1"></el-option>
                <el-option :key="2" label="展览" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="search"
                >搜索</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="exportExcel"
                >按搜索条件导出Excel</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" type="index" width="80">
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="id"-->
      <!--        label="id"-->
      <!--        min-width="100">-->
      <!--      </el-table-column>-->
      <el-table-column prop="vtype" label="类型" min-width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.vtype == 1">电影</span>
          <span v-if="scope.row.vtype == 2">展览</span>
        </template>
      </el-table-column>
      <el-table-column prop="site" label="地点/展览" min-width="120">
      </el-table-column>
      <el-table-column prop="film" label="影片名" min-width="120">
      </el-table-column>
      <el-table-column prop="astime" label="开始时间" min-width="100">
        <template slot-scope="scope">
          {{ fmtDate(scope.row.astime) }}
        </template>
      </el-table-column>
      <el-table-column prop="aetime" label="结束时间" min-width="100">
        <template slot-scope="scope">
          {{ fmtDate(scope.row.aetime) }}
        </template>
      </el-table-column>
      <el-table-column prop="vusername" label="预约人" min-width="100">
      </el-table-column>
      <el-table-column prop="company" label="单位" min-width="100">
      </el-table-column>
      <el-table-column prop="post" label="职位" min-width="100">
      </el-table-column>
      <el-table-column prop="vtime" label="核销时间" min-width="100">
        <template slot-scope="scope">
          {{ fmtDate(scope.row.vtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" min-width="100">
        <template slot-scope="scope">
          <!---1 待审核  0 未参观 1 已参观 2 取消 3 过期-->
          <span v-if="scope.row.status == -1">待审核</span>
          <span v-else-if="scope.row.status == 0">未使用</span>
          <span v-else-if="scope.row.status == 1">已使用</span>
          <span v-else-if="scope.row.status == 2">取消</span>
          <span v-else-if="scope.row.status == 3">过期</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :total="total"
          :page-sizes="[20, 50, 100]"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import { getVisitOrderList } from "../../../api/visit_order";
import VueUeditorWrap from "vue-ueditor-wrap";
import { getToken } from "../../../common/utils/auth";
import axios from "axios";

export default {
  name: "visitOrderManage",
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      editorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 500,
        // 初始容器宽度
        initialFrameWidth: "100%",
        zIndex: 5000,
        // 上传文件接口, 报错属于正常，若需要验证可使用(也是盗大神的)http://35.201.165.105:8000/controller.php
        // 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
        // serverUrl: '/static/UEditor/php/controller.php',
        UEDITOR_HOME_URL: "/UEditor/",
      },

      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {
        id: 0,
        site: "",
        film: "",
        stimes: "",
        totalTimes: "",
        content: "",
        img1: "",
        img2: "",
        vtype: "",
        sort: "",
        astime: "",
        aetime: "",
        hstime: "",
        hetime: "",
        address: "",
        lon: "",
        lat: "",
        city: "",
        types: "",
        notice: "",
      },
      date: {
        date1: "",
        date2: "",
        date3: "",
        date4: "",
      },
      editRules: {
        vtype: [{ required: true, message: "请选择类型", trigger: "blur" }],
        site: [{ required: true, message: "请填写地点", trigger: "blur" }],
      },
      loadingDiv: null,
      searchForm: {
        site: "",
        film: "",
        status: "",
        vtype: "",
      },
    };
  },
  watch: {},
  mounted() {
    this.getDataList(this.currentPage);

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 198;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight = document.documentElement.clientHeight - 198;
      });
    };

    this.init();
  },
  methods: {
    onDateChange(val) {
      if (this.date.date1) {
        this.editForm.astime = this.date.date1 / 1000;
      }
      if (this.date.date2) {
        this.editForm.aetime = this.date.date2 / 1000;
      }
      if (this.date.date3) {
        this.editForm.hstime = this.date.date3 / 1000;
      }
      if (this.date.date4) {
        this.editForm.hetime = this.date.date4 / 1000;
      }
    },
    fmtDate(val) {
      if (util.isNotEmpty(val) && val > 0) {
        return util.fmtDate(new Date(val * 1000));
      } else {
        return "";
      }
    },
    init() {},

    exportExcel() {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let param = {};

      for (var i in this.searchForm) {
        param[i] = this.searchForm[i];
      }

      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/plate/export-visit-order-excel", // 请求地址
        data: param, // 参数
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          console.log(res);
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");
          link.download = "展览展映按搜索条件导出_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },

    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      getVisitOrderList({
        pageSize: this.pageSize,
        pageNum: pageNum,
        site: this.searchForm.site,
        film: this.searchForm.film,
        status: this.searchForm.status,
        vtype: this.searchForm.vtype,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDataList(1);
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}
.el-form-item__content {
  // line-height: 0px;
}
.el-loading-custom-class {
  z-index: 99999 !important;
}
</style>
